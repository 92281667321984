<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择所属影院" v-model:value="formState.cinemaId" allow-clear
							style="width: 180px;">
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">
								{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="时间段" class="ui-form__item">
						<a-range-picker v-model:value="time" :disabledDate="disabledDate"></a-range-picker>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
					</a-col>
					<a-col :span="6" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>

			</a-form>

			<div style="margin-top: 20px;">
				<cTable ref="cTable" :isPage="false" :searchData="searchData" :isRequest="true" :requestFun="getfinanceDayRevenueStatementList" :columns="columns" rowKey="cinemaId"
					:scroll="{ x: 3000 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'time'">
							<div>
								<div>{{ transDateTime(searchData.revenueStartDay, 1) }}</div>
								至
								<div>{{ transDateTime(searchData.revenueEndDay, 1) }}</div>
							</div>
						</template>
						<template v-if="column.key == 'wechatRefundAmount'">
							{{ record.outcomeTotalAmount - record.wechatOutcomeFeeAmount }}
						</template>
					</template>
					<template ></template>
				</cTable>
			</div>
		</a-spin>

	</div>
</template>

<script>
	import cTable from '@/components/cTable/index.vue';
	import {
		getCinemaAllList
	} from '@/service/modules/cinema.js';
	import {
		getfinanceDayRevenueStatementList
	} from "@/service/modules/report";
	export default {
		components: {
			cTable
		},
		data() {
			return {
				showSendModal: false,
				showRejectModal: false,
				isEdit: false,
				isSee: false,
				id: 0,
				loading: false,
				cinemaAllList: [],
				searchData: {},
				modelRef: {},
				time: [],
				formState: {},
				columns: [{
					title: '日期',
					key: 'time',
					width: 120
				}, {
					title: '所属影院',
					dataIndex: 'cinemaName',
				}, {
					title: '影票销售金额',
					dataIndex: 'cinemaTotalAmount',
				}, {
					title: '改签金额',
					dataIndex: 'cinemaChargeAmount',
				}, {
					title: '商城',
					dataIndex: 'shopGoodsTotalAmount',
				}, {
					title: '卖品',
					dataIndex: 'snackGoodsTotalAmount',
				}, {
					title: '销售合计',
					dataIndex: 'incomeTotalAmount',
				}, {
					title: '销售微信手续费（0.54%）',
					dataIndex: 'wechatIncomeFeeAmount',
				}, {
					title: '微信到账预估金额（订单金额合计）',
					dataIndex: 'exceptionWechatIncomeTotalAmount',
				}, {
					title: '影片售后金额',
					dataIndex: 'cinemaRefundTotalAmount',
				}, {
					title: '改签退款',
					dataIndex: 'cinemaChargeRefundAmount',
				}, {
					title: '商城售后金额',
					dataIndex: 'shopGoodsRefundTotalAmount',
				}, {
					title: '卖品售后金额',
					dataIndex: 'snackGoodsRefundTotalAmount',
				}, {
					title: '退款合计',
					dataIndex: 'outcomeTotalAmount',
				}, {
					title: '退款微信手续费（0.54%）',
					dataIndex: 'wechatOutcomeFeeAmount',
				}, {
					title: '退款到账扣除金额合计',
					key: 'wechatRefundAmount'
				}, {
					title: '预计打款金额',
					dataIndex: 'estimateIncomeFee',
				}],
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			this.time = [this.moment().subtract(1, 'day').startOf(), this.moment().subtract(1, 'day').endOf()];
			this.getAllCinemaList();
		},
		mounted() {
			this.onSearch();
		},
		methods: {
			getfinanceDayRevenueStatementList: getfinanceDayRevenueStatementList,
			reset() {
				this.time = [this.moment().subtract(1, 'day').startOf(), this.moment().subtract(1, 'day').endOf()];
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				if (this.time && this.time.length) {
					this.searchData.revenueStartDay = this.time[0].startOf('days').unix();
					this.searchData.revenueEndDay = this.time[1].endOf('days').unix();
				}
				this.getData();
			},
			async getData() {
				this.$refs.cTable.toQuery();
				return;
				this.loading = true;
				try {
					let ret = await getfinanceDayRevenueStatementList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getAllCinemaList() {
				this.cinemaAllList = [];
				let ret = await getCinemaAllList({});
				if (ret.code === 200) {
					this.cinemaAllList = ret.data;
				}
			},
			disabledDate(current) {
			    return current && current > this.moment().endOf('day');
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>